.App {
  text-align: left;
}

.App-header {
  background-color: #000270;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 3rem;
}

.App-header > div {
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: x-large;
}

.App-header > div > img {
  margin-right: 10px;
  height: 40px;
  width: auto;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 25vh;
}
