.manage-track-record .track-record-textfield {
    margin: 0 !important
}

@media (max-width: 600px) { 
    .manage-track-record {
        flex-direction: column !important;
    }
    .manage-track-record .track-record-action-buttons {
        margin: 8px 0 0 0;
    }
}